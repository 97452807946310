@import "~@/styles/variables/variables.scss";

















































































































































































































































































































.gc-customer-cascader {
	.el-cascader-panel {
		.el-cascader-menu {
			&:nth-child(-n + 2) {
				display: none;
			}
			&:last-child {
				li {
					&:first-child {
						display: none;
					}
				}
			}
		}
	}
}
