@import "~@/styles/variables/variables.scss";



























































































































































































.map-box {
	position: relative;
	.map-select {
		padding: 0px;
		margin: 0px;
		width: 100%;
		height: 300px;
	}
	.inline-input {
		position: absolute;
		display: inline-block;
		width: calc(100% - 40px);
		top: 20px;
		left: 50%;
		transform: translateX(-50%);
	}
}
