@import "~@/styles/variables/variables.scss";









































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.install-position-content {
	display: flex;
	align-items: flex-start;
	gap: 12px;
	::v-deep .el-form-item {
		flex: 0 0 calc(50% - 28px);
	}
	::v-deep .el-input--prefix .el-input__inner {
		padding-left: 42px;
	}
	.icon {
		display: inline-block;
		width: 32px;
		height: 32px;
		cursor: pointer;
	}
}
.device-dialog-content {
	display: flex;
	flex-direction: column;
	.top {
		border-bottom: 1px dashed #cccccc;
		margin-bottom: 20px;
	}
	.title {
		font-size: 14px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #ababab;
		padding-bottom: 20px;
	}

	::v-deep .is-radio {
		display: flex;
		.el-form-item__label {
			margin-right: 24px;
		}
	}
}
.map-box {
	width: 100%;
	height: 300px;
	border: 1px solid #eee;
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
	height: 0;
}
::v-deep .install-position {
	.el-form-item__content {
		display: flex;
		.icon {
			display: inline-block;
			width: 32px;
			height: 32px;
			margin-left: 12px;
			cursor: pointer;
		}
	}
}
::v-deep .el-form-item--small .el-form-item__label {
	height: 32px;
}
